<template>
  <app-container
    icon="account_circle"
    colWidth="col-11 col-lg-10"
    v-if="!isTextLoading"
  >
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="register">
        <app-form
          v-if="showForm"
          :form="form"
          :model="model"
          :rules="rules"
          :itemToUpdate="itemToUpdate"
          :button="texts.submit_button"
          @submitted="handleSubmit"
        >
          <template #button>
            <button
              class="btn-outlined"
              @click.prevent="$router.push({ name: 'Password' })"
            >
              <router-link :to="{ name: 'Password' }">
                {{ texts.password_button }}</router-link
              >
            </button>
          </template>
        </app-form>
      </div>
    </template>
  </app-container>
</template>

<script>
/**
 * TODO: Fix itemToUpdate on page reload
 */

import Database from '@/utils/services/Database';
import Utilities from '@/utils/services/Utilities';
import useTexts from '@/mixins/useTexts';

import { form, model, rules } from '@/config/forms/auth/profile';

export default {
  name: 'UserProfile',
  mixins: [useTexts],
  data() {
    return {
      form: [],
      model: {},
      rules: {},
      itemToUpdate: {},
      page: 'user_profile',
      showForm: false,
    };
  },
  async created() {
    this.showForm = false;

    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));

    const user = await this.$store.dispatch('getUser');
    this.itemToUpdate = user;

    this.showForm = true;
  },
  methods: {
    async handleSubmit(data) {
      const status = await Database.updateUser(data);

      if (status !== 200)
        Utilities.showMessage('error', 'update_profile_error');

      const user = await this.$store.dispatch('getUser');
      this.itemToUpdate = user;
      Utilities.showMessage('success', 'update_profile_success');
    },
  },
};
</script>

<style lang="scss">
.profile-table {
  border-collapse: collapse;
  margin-bottom: 2rem;
  tbody {
    tr {
      td {
        padding: 0.5rem 2rem;
        text-align: start;
        border: 1px solid $light-grey;
        &:first-child {
          background-color: $dark;
          border: 1px solid $light-grey;
          color: $light;
          font-weight: bold;
          width: 25%;
        }
        &:last-child {
          width: 15%;
        }
        &.icon-td {
          text-align: center;
          i {
            cursor: pointer;
            transition: $trs-default;
            font-size: 1.5rem;
            color: $secondary;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
</style>
